import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Bounds, GizmoHelper, GizmoViewport, OrbitControls, Lightformer, Environment, Stats, ContactShadows } from '@react-three/drei';
import classnames from 'classnames';
import { LineBasicMaterial, MeshMatcapMaterial, MeshStandardMaterial, MOUSE } from 'three';
import { modelExplode } from './components/util';
import {A1} from './components/a';
import {A2} from './components/b';
import {A3} from './components/c';

function Orbit ( props ){
  const moved = useState( false );
  useFrame( state => {
    if( moved.value ) {
      state.performance.regress();
    }
  });
  return <OrbitControls ref={props.entity} dampingFactor={0.1} makeDefault autoRotate={props.autoRotate} rotateSpeed={3} onStart={() => {
    moved.value = true;
  }} onEnd={ e => {
    moved.value = false;
  }}  />
}

const index = parseFloat((window.location.search).split('=')[1]);

function App() {

  const [ modelIndex ] = useState( index );
  const [ modelLoaded, setModelLoaded ] = useState( false );

  const [ pan, setPan ] = useState( false );
  const [ autoRotate, setAutoRotate ] = useState( false );
  const [ lineChildrenVisible, setLineChildrenVisible ] = useState( false );
  const [ lineColor, setLineColor ] = useState( false );
  const [ lineNoColor, setLineNoColor ] = useState( true );
  const [ lineTransparent, setLineTransparent ] = useState( false );
  const [ bloom, setBloom ] = useState( false );
  const [ bloomFactor, setBloomFactor ] = useState( 0 );

  const a1 = useRef();
  const orbit = useRef();

  useEffect(() => {
  }, []);

  return (
      <div className={classnames({'app': true, 'pan': pan })} onClick={() => {
        setLineChildrenVisible( false );
        setBloom( false );
      }}>
      <Suspense fallback={<span className='loading'>Loading...</span>}>
          <Canvas gl={{ antialias: true }} camera={{ position: [-80, 50, 100], fov: 35, near: 0.1, far: 5000 }}>
            <ambientLight color="#f6f6f6" intensity={0.25} />
            <Bounds fit clip margin={2}>
                {
                  modelIndex === 0 && <A1 entity={a1} callback={() => {
                    setModelLoaded( true );
                  }} />
                }
                {
                  modelIndex === 1 && <A2 entity={a1} callback={() => {
                    setModelLoaded( true );
                  }} />
                }
                {
                  modelIndex === 2 && <A3 entity={a1} callback={() => {
                    setModelLoaded( true );
                  }} />
                }
            </Bounds>
            {
              bloomFactor === 0 && modelIndex === 0 && <ContactShadows position={[0,-35,0]} 
                opacity={0.5} scale={200} blur={1} far={100} resolution={256} color="#000" />
            }
            {
              bloomFactor === 0 && modelIndex === 1 && <ContactShadows position={[0,-55,0]} 
                opacity={0.5} scale={600} blur={0.5} far={300} resolution={256} color="#000" />
            }
            <Environment resolution={256}>
              <group rotation={[-Math.PI / 2, 0, 0]}>
                <Lightformer castShadow receiveShadow intensity={55} rotation-z={-Math.PI / 2} rotation-x={Math.PI / 2} position={[0, -40, 0]} scale={[10, 10, 1]} />
                <Lightformer castShadow receiveShadow intensity={55} rotation-z={-Math.PI / 2} rotation-x={Math.PI/2}  position={[0, 30, 0]} scale={[10, 10, 1]} />
                <Lightformer castShadow receiveShadow intensity={55} rotation-y={Math.PI / 2} position={[-50, -0, 0]} scale={[10, 10, 1]} />
                <Lightformer castShadow receiveShadow intensity={55} rotation-y={Math.PI / 2} position={[50, 0, 0]} scale={[10, 10, 1]} />
                <Lightformer castShadow receiveShadow intensity={55} rotation-z={Math.PI / 2} position={[0, 0 , 60]} scale={[10, 10, 1]} />
                <Lightformer castShadow receiveShadow intensity={55} rotation-z={Math.PI / 2} position={[0, 0, -60]} scale={[10, 10, 1]} />
              </group>
            </Environment>

            <Orbit autoRotate={autoRotate} entity={orbit} />

            <GizmoHelper alignment="bottom-right" margin={[80, 80]} renderPriority={0}>
              <GizmoViewport axisColors={["hotpink", "aquamarine", "#3498DB"]} labelColor="black" />
            </GizmoHelper>

            <Stats />
          </Canvas>
        </Suspense>
        <Suspense>
          {
            modelLoaded && <div className="items">
            <div className={classnames({ 'item': true, 'active': pan })} onClick={e => {
              e.stopPropagation();
              let _pan = !pan;
              setPan( _pan );
              orbit.current.enablePan = true;
              orbit.current.enableZoom = true;
              orbit.current.enableRotate = !_pan;
              if( _pan ) {
                orbit.current.mouseButtons = {
                  LEFT: MOUSE.PAN
                }
              }
              else {
                orbit.current.mouseButtons = {
                  LEFT: MOUSE.ROTATE,
                  MIDDLE: MOUSE.DOLLY,
                  RIGHT:MOUSE.PAN
                }
              }
            }}>
              <div><img src="/statics/icon/pan.png" width={30} alt="平移" /></div>
              <div>平移</div>
            </div>
            <div className={classnames({ 'item': true, 'active': autoRotate })} onClick={() => {
              setAutoRotate(!autoRotate);
            }}>
              <div><img src="/statics/icon/auto_rotation.png" width={30} alt="播放" /></div>
              <div>播放</div>
            </div>
            <div className={classnames({ 'item': true, 'active': lineChildrenVisible })} onClick={e => {
              e.stopPropagation();
              setLineChildrenVisible( true );
              setBloom( false );
            }}>
              <div><img src="/statics/icon/line_transparent.png" width={30} alt="显示" /></div>
              <div>显示</div>
              <div className={classnames({ 'children': true, 'hide': !lineChildrenVisible })}>
                <div className={classnames({ 'item': true, 'active': lineNoColor })} onClick={() => {
                  setLineNoColor( true );
                  setLineColor( false );
                  setLineTransparent( false );
                  a1.current.traverse( child => {
                    if( child.name === 'edge' ){
                      child.visible = false;
                    }
                    if( child.isMesh && (child.material instanceof MeshStandardMaterial || child.material instanceof MeshMatcapMaterial)) {
                      child.material.transparent = false;
                      child.material.opacity = 1;
                    }
                    else if( child.material instanceof LineBasicMaterial )  {
                      child.material.transparent = false;
                    }
                  });
                }}>
                  <div><img src="/statics/icon/color_noline.png" width={30} alt="不带边上色" /></div>
                  <div>不带边上色</div>
                </div>
                <div className={classnames({ 'item': true, 'active': lineColor })} onClick={() => {
                  setLineNoColor( false );
                  setLineColor( true );
                  setLineTransparent( false );
                  a1.current.traverse( child => {
                    if( child.name === 'edge' ){
                      child.visible = true;
                    }
                    if( child.isMesh && (child.material instanceof MeshStandardMaterial || child.material instanceof MeshMatcapMaterial)) {
                      child.material.transparent = false;
                      child.material.opacity = 1;
                    }
                    else if( child.material instanceof LineBasicMaterial )  {
                      child.material.transparent = false;
                    }
                  });
                }}>
                  <div><img src="/statics/icon/color_line.png" width={30} alt="带边上色" /></div>
                  <div>带边上色</div>
                </div>
                <div className={classnames({ 'item': true, 'active': lineTransparent })} onClick={() => {
                  setLineNoColor( false );
                  setLineColor( false );
                  setLineTransparent( true );
                  a1.current.traverse( child => {
                    if( child.name === 'edge' ){
                      child.visible = true;
                    }
                    if( child.isMesh && (child.material instanceof MeshStandardMaterial || child.material instanceof MeshMatcapMaterial)) {
                      child.material.transparent = true;
                      child.material.opacity = 0;
                    }
                    else if( child.material instanceof LineBasicMaterial )  {
                      child.material.transparent = true;
                    }
                  });
                }}>
                  <div><img src="/statics/icon/line_transparent.png" width={30} alt="带边线透明" /></div>
                  <div>带边线透明</div>
                </div>
              </div>
            </div>
            <div className={classnames({ 'item': true, 'active': bloom })} onClick={(e) => {
              e.stopPropagation();
              setBloom( true );
              setLineChildrenVisible( false );
            }}>
              <div><img src="/statics/icon/boom.png" width={30} alt="爆炸" /></div>
              <div>爆炸</div>
              <div className={classnames({ 'children': true, 'bloom': true, 'hide': !bloom })}>
                <input type="range" min={0} max={100} defaultValue={0} onChange={e => {
                  let num = parseFloat(e.target.value);
                  setBloomFactor( num )
                  modelExplode( a1.current, num );
                }}/>
              </div>
            </div>
          </div>
          }
        </Suspense>
      </div>
  );
}

export default App;
