
import { createAsset } from 'use-asset';
import { DRACOLoader, GLTFLoader } from 'three-stdlib';
import { Box3, Vector3 } from 'three';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( '//static.sanxiangti.com/statics/draco/1.4.3/' );

const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader( dracoLoader );

export function getModel ( baseUrl, path ){

  const chunkNum = 6;

  let asset = createAsset(async () => {

    const size = await getModelSize( baseUrl + path );
    const chunkSize = Math.ceil( size / chunkNum );
    const jobs = [];

    for( let i = 0, len = chunkNum; i < len; i++ ) {
      jobs.push( func( i ) );
    }

    function func(num){
      let start = num * chunkSize;
      let end = (num + 1) * chunkSize - 1;
      return httpRequest( baseUrl + path, {
        Range: `bytes=${start}-${end}`
      });
    }

    return Promise.all( jobs ).then( async data => {
      let arraybuffer = await (new Blob( data ).arrayBuffer());
      return new Promise((resolve, reject) => {
        gltfLoader.parse( arraybuffer, baseUrl, resolve, reject );
      });
    });
  });

  asset.preload();

  return asset;
}

export function getModelSize ( url ){
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open( 'HEAD', url, true );
    request.onreadystatechange = data => {
      if( request.status === 200 ){
        let size = request.getResponseHeader('Content-Length');
        resolve( parseFloat( size ) );
      }
    }
    request.send();
  });
}

export function httpRequest( url, headers, options = {} ){
  return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open( 'GET', url, true );
      request.withCredentials = options.withCredentials;
      request.responseType = 'blob';
      for( let key in headers ) {
        request.setRequestHeader( key, headers[ key ] );
      }
      request.addEventListener( 'load', function ( event ) {
          var response = this.response;
          if ( this.status === 200 || this.status === 206 || this.status === 304 ) {
              resolve( response );
          }
          else {
              reject( event );
          }
      });
      request.addEventListener('error', function ( event ){
          reject( event );
      });
      request.send();
  });
}

export function modelExplode( group, num ){
  
  var childBox = new Box3();
  var box = childBox.setFromObject( group );
  var modelWorldCenter = new Vector3(0,0,0).addVectors(box.max,box.min).multiplyScalar(0.5);//模型中心坐标

  group.traverse( child => {

    if( !child.isMesh || child.name === 'mask' ) return;

    childBox.setFromObject( child );

    if(!(childBox.min && childBox.max)) return;

    let max = childBox.max;
    let min = childBox.min;

    let childCenter = new Vector3().addVectors( max, min ).multiplyScalar( 0.5 );
    let direction = new Vector3().subVectors( childCenter, modelWorldCenter ).normalize();

    let factor = Math.abs(childCenter.x - modelWorldCenter.x) / 120;
    if( factor < 1 ) factor = 1;

    child.position.copy( direction ).multiplyScalar( num * factor );
  });
}
