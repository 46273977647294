import React, { useEffect } from "react";
import { EdgesGeometry, LineBasicMaterial, LineSegments, MeshStandardMaterial, MeshMatcapMaterial, DoubleSide, sRGBEncoding, PlaneGeometry, MeshBasicMaterial, FrontSide, Mesh } from "three";
import { getModel } from "./util";
import { useTexture } from "@react-three/drei";
import { createAsset } from "use-asset";

//材质球

const index = parseFloat((window.location.search).split('=')[1]);

var asset;

if( index === 1 ) {
  asset = getModel( "https://static.sanxiangti.com/statics/models/", "b48a1805-93ed-4391-ad40-4772558c49ed.binz" );
}
else {
  asset = createAsset(() => {
    return null;
  });
}

export function A2 ( props ){

  const object = asset.read();

  const texture01 = useTexture("https://static.sanxiangti.com/statics/models/textures02/matcap-01.jpg");
  const texture02 = useTexture("https://static.sanxiangti.com/statics/models/textures02/matcap-02.jpg");
  const texture03 = useTexture("https://static.sanxiangti.com/statics/models/textures02/matcap-03.jpg");
  const texture04 = useTexture("https://static.sanxiangti.com/statics/models/textures02/matcap-04.jpg");
  const texture05 = useTexture("https://static.sanxiangti.com/statics/models/textures02/matcap-05.jpg");
  const mask = useTexture('/statics/mask02.png');
  texture01.encoding = texture02.encoding = texture03.encoding = texture04.encoding = texture05.encoding = sRGBEncoding;

  const mat1 = new MeshMatcapMaterial({ matcap: texture01, color: 0xffffff, transparent: false, opacity: 1, polygonOffset: true, side: DoubleSide }); 
  const mat2 = new MeshMatcapMaterial({ matcap: texture02, color: 0x818e9e, transparent: false, opacity: 0.98, polygonOffset: true, side: DoubleSide }); 
  const mat3 = new MeshMatcapMaterial({ matcap: texture03, color: 0xf0f0f0, transparent: false, opacity: 0.98, polygonOffset: true, side: DoubleSide }); 
  const mat4 = new MeshMatcapMaterial({ matcap: texture04, color: 0xb1bdc9, transparent: false, opacity: 0.98, polygonOffset: true, side: DoubleSide }); 
  const mat5 = new MeshMatcapMaterial({ matcap: texture05, color: 0x6b6b6b, transparent: false, opacity: 0.98, polygonOffset: true, side: DoubleSide }); 

  object.scene.traverse( child => {
    if(!(child.isMesh && child.material instanceof MeshStandardMaterial)) return;

    if( child.name === 'Object_40' ) {
      let geo = new PlaneGeometry( 35, 25, 10 );
      mask.encoding = sRGBEncoding;
      mask.needsUpdate = true;
      let mat = new MeshBasicMaterial({ map: mask, transparent: true, side: FrontSide });
      let desc = new Mesh( geo, mat );
      desc.position.set( 85, 0, 0 );
      desc.rotateY( Math.PI / 180 * 90 );
      desc.name = 'mask';
      child.add( desc );
    }

    switch( child.material.name ) {
      case "材质-01": {
        child.material = mat1;
        break;
      }
      case "材质-02": {
        child.material = mat2;
        break;
      }
      case "材质-03": {
        child.material = mat3;
        break;
      }
      case "材质-04": {
        child.material = mat4;
        break;
      }
      case "材质-05": {
        child.material = mat5;
        break;
      }
      default: {
      }
    }
  });

  function initLineSegments (){
    props.entity.current.traverse( child => {
      if( child.isMesh && (child.material instanceof MeshStandardMaterial || child.material instanceof MeshMatcapMaterial) ) {
        if( child.name === 'mask' ) return;
        let geo = new EdgesGeometry( child.geometry, 15 );
        let mat = new LineBasicMaterial({ color: 0x000000 });
        mat.name = 'line';
        let mesh = new LineSegments( geo, mat );
        mesh.name = 'edge';
        mesh.visible = false;
        child.add( mesh );
      }
    });
  }
  useEffect(() => {
    initLineSegments();
    props.callback && props.callback();
    return () => {
    }
  }, []);
  return <group>
    <primitive object={object.scene} ref={props.entity} />
  </group>
}
