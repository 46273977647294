import React, { useEffect } from "react";
import { EdgesGeometry, LineBasicMaterial, PlaneBufferGeometry, Mesh, MeshBasicMaterial, LineSegments, MeshStandardMaterial, MeshMatcapMaterial, DoubleSide, Color, sRGBEncoding, MultiplyBlending, FrontSide, LinearEncoding } from "three";
import { getModel } from "./util";
import { useTexture } from "@react-three/drei";
import { createAsset } from "use-asset";

// getModelSize( "https://static.sanxiangti.com/statics/models/aa37f53b-0333-4263-a4ae-40fa5224b01a.binz" );
//材质球
const index = parseFloat((window.location.search).split('=')[1]);

var asset;

if( index === 0 ) {
  asset = getModel( "https://static.sanxiangti.com/statics/models/", "17bfd900-2fe7-4e38-8321-e2f995b129a7.binz" );
}
else {
  asset = createAsset(() => {
    return null;
  });
}

export function A1 ( props ){

  const object = asset.read();

  const texture01 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-01.jpg");
  const texture02 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-02.jpg");
  const texture03 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-03.jpg");
  const texture04 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-04.jpg");
  const texture05 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-05.jpg");
  const texture06 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-06.jpg");
  const texture07 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-07.jpg");
  const texture08 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-08.jpg");
  const texture09 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-09.jpg");
  const texture10 = useTexture("https://static.sanxiangti.com/statics/models/textures/matcap-10.jpg");
  const mask = useTexture('/statics/mask.png');
  texture01.encoding = texture02.encoding = texture03.encoding = texture04.encoding = texture05.encoding = texture06.encoding = texture07.encoding = texture08.encoding = texture09.encoding = texture10.encoding = sRGBEncoding;

  const mat1 = new MeshMatcapMaterial({ matcap: texture01, color: 0xffffff, transparent: false, opacity: 1, polygonOffset: true, side: DoubleSide }); 
  const mat2 = new MeshMatcapMaterial({ matcap: texture02, color: 0xffffff, transparent: false, opacity: 0.98, polygonOffset: true, side: DoubleSide }); 
  const mat3 = new MeshMatcapMaterial({ matcap: texture03, color: 0xf0f0f0, transparent: false, opacity: 0.98, polygonOffset: true, side: DoubleSide }); 

  object.scene.traverse( child => {
    if(!(child.isMesh && child.material instanceof MeshStandardMaterial)) return;

    child.material.shadowSide = FrontSide;
    child.material.receiveShadow = true;
    child.material.castShadow = true;

    if( child.name === 'Object_2' ) {
      let geo = new PlaneBufferGeometry( 17, 30, 10 );
      mask.encoding = sRGBEncoding;
      mask.needsUpdate = true;
      let mat = new MeshBasicMaterial({ map: mask });
      mat.transparent = true;
      // mat.alphaTest = 0.5;
      mat.side = FrontSide;
      let desc = new Mesh( geo, mat );
      desc.position.set( 9.5, 31.2, 7.5 );
      desc.rotateY( Math.PI / 180 * -90 );
      desc.rotateX( Math.PI / 180 * -90 )
      desc.name = 'mask';
      child.add( desc );

      window.desc = desc;
    }

    switch( child.material.name ) {
      case "材质-01": {
        child.material = mat1;
        break;
      }
      case "材质-02": {
        child.material = mat2;
        break;
      }
      case "材质-03": {
        child.material = mat3;
        break;
      }
      case "材质-04": {
        child.material.map = texture04;
        child.material.color = new Color( 0xf0f4f7 );
        child.material.metalness = 0.6;
        child.material.roughness = 0.3;
        break;
      }
      case "材质-05": {
        child.material.map = texture05;
        child.material.color = new Color( 0xffffff );
        child.material.metalness = 0.6;
        child.material.roughness = 0.3;
        break;
      }
      case "材质-06": {
        child.material.map = texture06;
        child.material.color = new Color( 0xd1abab );
        child.material.metalness = 0.6;
        child.material.roughness = 0.99;
        break;
      }
      case "材质-07": {
        child.material.map = texture07;
        child.material.color = new Color( 0xc0c1c2 );
        child.material.metalness = 0.6;
        child.material.roughness = 0.3;
        break;
      }
      case "材质-08": {
        child.material.map = texture08;
        child.material.color = new Color( 0xe4eff7 );
        child.material.metalness = 0.6;
        child.material.roughness = 0.3;
        break;
      }
      case "材质-09": {
        child.material.map = texture09;
        child.material.color = new Color( 0xffeec9 );
        child.material.metalness = 0.6;
        child.material.roughness = 0.3;
        break;
      }
      case "材质-10": {
        child.material.map = texture10;
        child.material.color = new Color( 0x541b1b );
        child.material.metalness = 0.6;
        child.material.roughness = 0.3;
        break;
      }
      default: {
      }
    }
  });

  function initLineSegments (){
    props.entity.current.traverse( child => {
      if( child.isMesh && child.material ) {
        if( child.name === 'mask' ) return;
        let geo = new EdgesGeometry( child.geometry, 20 );
        let mat = new LineBasicMaterial({ color: 0x000000 });
        mat.name = 'line';
        let mesh = new LineSegments( geo, mat );
        mesh.name = 'edge';
        mesh.visible = false;
        child.add( mesh );
      }
    });
  }
  useEffect(() => {
    initLineSegments();
    props.callback && props.callback();
    return () => {
    }
  }, []);
  return <group>
    <primitive object={object.scene} ref={props.entity} />
  </group>
}
